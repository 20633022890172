<template>
  <div>
    <md-table
      md-card
      table-header-color="blue"
      :value="charactersLevelUpPrices"
    >
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Стоимость прокачки уровней героев</h1>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Уровень">
          {{ item.level }}
        </md-table-cell>

        <md-table-cell md-label="Стоимость в золоте">
          {{ item.priceGold }}
        </md-table-cell>

        <md-table-cell md-label="Стоимость в фрагментах">
          {{ item.priceFragments }}
        </md-table-cell>

        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-primary"
            @click="openItemInModal(item)"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Изменить</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <CharacterLevelUpPricesModal
      v-if="showModal && !!selectedItem"
      :item="selectedItem"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    CharacterLevelUpPricesModal: () =>
      import("@/components/Modals/CharacterLevelUpPricesModal"),
  },

  data() {
    return {
      selectedItem: null,
      showModal: false,
    };
  },

  computed: {
    ...mapState("levelUpPrices", ["charactersLevelUpPrices"]),
  },

  async mounted() {
    this.showLoader(true);
    await this.getCharacterLevelUpPrices();
    this.showLoader(false);
  },

  methods: {
    ...mapActions("levelUpPrices", ["getCharacterLevelUpPrices"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    async closeModal(needUpdate) {
      this.showModal = false;
      this.selectedItem = null;

      if (needUpdate == true) {
        this.showLoader(true);
        await this.getCharacterLevelUpPrices();
        this.showLoader(false);
      }
    },

    openItemInModal(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
